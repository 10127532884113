import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Markdown from "markdown-to-jsx";

import * as css from "../styles/Artist.module.scss";
import * as cssArticles from "../styles/Articles.module.scss";

import Layout from "../components/Layout";
import ArticleButton from "../components/ArticleButton";
import CreationButton from "../components/CreationButton";

import I18n from "../components/I18n";
import { sum, unique } from "../utils/utils";

const join = (...args) => args.filter((v) => v).join(" ");
const Artist = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: {
    strapi: {
      theme = {},
      artist: {
        data: {
          attributes: {
            creations: { data: creations = [] } = {},
            movies: { data: movies = [] } = {},
            netarts: { data: netarts = [] } = {},
            image,
            firstName,
            lastName,
            biography,
            imdbUrl,
            wikipediaUrl,
          },
        },
      } = {},
    } = {},
  },
}) => {
  return (
    <Layout
      theme={theme}
      className={css.Artist}
      locale={locale}
      locales={locales}
      pathname={pathname}
      canonical={pathname}
      meta={(meta, { url }) => [
        ...meta,
        { name: "description", content: biography },
        { property: "og:type", content: "profile" },
        { property: "og:title", content: join(firstName, lastName) },
        { property: "og:description", content: biography },
        { property: "og:image", content: image?.url },
        { property: "og:url", content: `${url}${pathname}` },
      ]}
      jsonLd={(jsonld, { url }) => [
        ...jsonld,
        {
          "@context": "http://schema.org",
          "@type": "Person",
          name: join(firstName, lastName),
          image: image?.url,
          description: biography,
          jobTitle: "Director",
          sameAs: [imdbUrl, wikipediaUrl].filter((v) => v),
          url: `${url}${pathname}`,
        },
      ]}
    >
      <section className="main">
        <GatsbyImage
          image={getImage(image?.data?.attributes?.fileS3)}
          objectFit="contain"
          objectPosition="top"
        />
        <div className="informations">
          <h1>{join(firstName, lastName)}</h1>
          {biography && <Markdown>{biography}</Markdown>}
        </div>
      </section>
      {movies?.length > 0 && (
        <section className="films">
          <I18n id="title.films" component="h2" />
          {movies.map(({ id, attributes: { poster }, attributes }) => (
            <CreationButton
              key={id}
              variant={poster?.data ? "poster" : "standard"}
              {...attributes}
              withReleaseYear
            />
          ))}
        </section>
      )}
      {netarts?.length > 0 && (
        <section className="netarts">
          <I18n id="title.netarts" component="h2" />
          {netarts
            .filter(({ attributes: { image } }) => image)
            .map(({ id, attributes: { slug }, attributes }) => (
              <CreationButton
                key={id}
                to={`/artnum/${slug}`}
                variant="standard"
                {...attributes}
                withReleaseYear
                watermark
              />
            ))}
        </section>
      )}
      {sum(
        creations,
        ({ attributes: { articles } }) => articles?.data?.length
      ) > 0 && (
        <section className={cssArticles.Articles}>
          <I18n id="title.news" component="h2" />
          {creations
            ?.flatMap(({ attributes: { articles } }) => articles?.data)
            ?.sort(
              (
                { attributes: { publicationDate: a } },
                { attributes: { publicationDate: b } }
              ) => new Date(b).getTime() - new Date(a).getTime()
            )
            ?.filter(unique(({ id }) => id))
            ?.map(({ id, attributes }) => (
              <ArticleButton key={id} {...attributes} />
            ))}
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: ID!) {
    strapi {
      artist(id: $id) {
        data {
          id
          attributes {
            slug
            biography
            firstName: first_name
            lastName: last_name
            imdb_url
            wikipedia_url
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            movies: creations(
              sort: "release_date:desc"
              filters: { productor: { eq: "pelleas" } }
            ) {
              data {
                id
                attributes {
                  slug
                  status
                  releaseDate: release_date
                  title
                  directors {
                    data {
                      id
                      attributes {
                        firstName: first_name
                        lastName: last_name
                      }
                    }
                  }
                  image {
                    data {
                      id
                      attributes {
                        url
                        formats
                        fileS3(minFormat: "small") {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1.5625
                              quality: 90
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                  poster {
                    data {
                      id
                      attributes {
                        url
                        formats
                        fileS3(minFormat: "small") {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 0.7261
                              quality: 90
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            netarts: creations(
              sort: "release_date:desc"
              filters: { productor: { eq: "balthuslab" } }
            ) {
              data {
                id
                attributes {
                  title
                  slug
                  releaseDate: release_date
                  directors {
                    data {
                      id
                      attributes {
                        firstName: first_name
                        lastName: last_name
                      }
                    }
                  }
                  image {
                    data {
                      id
                      attributes {
                        url
                        formats
                        fileS3(minFormat: "small") {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1.5625
                              quality: 90
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            creations {
              data {
                id
                attributes {
                  articles(sort: "publication_date:desc") {
                    data {
                      id
                      attributes {
                        title
                        description
                        source
                        url: link
                        publicationDate: publication_date
                        image {
                          data {
                            id
                            attributes {
                              url
                              formats
                              fileS3(minFormat: "small") {
                                childImageSharp {
                                  gatsbyImageData(
                                    aspectRatio: 1
                                    quality: 90
                                    formats: [AUTO, WEBP]
                                  )
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Artist;
